<template>
    <section class="section">
    <h1 class="mb-3"><i class="bi bi-chat-right-text text-primary"></i> 特定商取引法に基づく表記</h1>

    <img src="/img/notation.png" alt="" style="max-width:100%;">
    </section>
</template>

<script>
export default {
    name: 'PageNotation',
    components: {

    },
    data: () => {
        return {}
    }
}
</script>

<style scoped>

</style>
